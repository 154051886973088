import { Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import {
  doc,
  Firestore,
  collection,
  collectionData,
  where,
  query,
  updateDoc,
  setDoc,
  addDoc,
  deleteDoc,
  getDocs,
  arrayUnion,
  arrayRemove,
  GeoPoint
} from "@angular/fire/firestore";
import { Functions, httpsCallable } from "@angular/fire/functions";

@Injectable({
  providedIn: "root",
})
export class RestoService {
  userData: any;
  uid: string;
  restoUID: any;
  restoUIDForAdmin: any;

  nomResto = signal<string>('');
  imgResto = signal<string>('');
  nomStaff = signal<string>('');

  constructor(
    private db: Firestore,
    private functions: Functions,
    private route: Router
  ) {}

  // admin

  async addResto(resto) {
    await addDoc(collection(this.db, "restaurants"), resto);
  }

  async enabledResto(restoUID, statut) {
    const newStatut = { statut };
    await updateDoc(doc(this.db, "restaurants", restoUID), newStatut);
  }

  async disabledResto(restoUID, statut) {
    const newStatut = { statut };
    await updateDoc(doc(this.db, "restaurants", restoUID), newStatut);
  }

  // Restaurateur

  async updateResto(userUID: string, resto: any) {
    await updateDoc(doc(this.db, "restaurants", userUID), resto);
  }

  async updateData(userUID: string, data: any) {
    await setDoc(doc(this.db, "restaurants", userUID), data);
  }

  getResto() {
    return collectionData<any>(collection(this.db, "restaurants"));
  }

  getOneResto(userUID: string) {
    return collectionData<any>(
      query(collection(this.db, "restaurants"), where("uidUser", "==", userUID))
    );
  }

  getIdResto(userUID: string) {
    return collectionData<any>(
      query(collection(this.db, "staff"), where("staffID", "==", userUID))
    );
  }

  setNameResto(data) {
    this.nomResto.set(data);
  }

  setImgResto(data) {
    this.imgResto.set(data);
  }

  setNameStaff(data) {
    this.nomStaff.set(data);
  }

  getUidRestoForAdmin(uidResto: string) {
    this.restoUIDForAdmin = uidResto;
    this.route.navigate(["dashboard/details"]);
  }

  getRestoForAdmin(userUID: string) {
    return collectionData<any>(
      query(collection(this.db, "restaurants"), where("uidUser", "==", userUID))
    );
  }

  getRestoBankInfo(restoUID: string) {
    return collectionData<any>(
      collection(this.db, "restaurants", restoUID, "bank")
    );
  }

  async updateAcompte(restoUID: string, acompte: number) {
    await updateDoc(doc(this.db, "restaurants", restoUID, "bank", restoUID), {
      acompte: acompte,
    });
  }

  async addAcompte(restoUID: string, period: any) {
    await updateDoc(doc(this.db, "restaurants", restoUID, "bank", restoUID), {
      periodAcompte: arrayUnion(period),
    });
  }

  async deletePeriodeAcompte(restoUID: string, period: any) {
    await updateDoc(doc(this.db, "restaurants", restoUID, "bank", restoUID), {
      periodAcompte: arrayRemove(period),
    });
  }

  // Staff

  async addStaff(newStaff: any) {
    await addDoc(collection(this.db, "staff"), newStaff);
  }

  getStaff(userUID: string) {
    return collectionData<any>(
      query(collection(this.db, "staff"), where("restoUID", "==", userUID))
    );
  }

  async updateStaff(staffID, value) {
    const q = query(
      collection(this.db, "staff"),
      where("staffID", "==", staffID)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (result) => {
      const email = { emailGestion: value };

      await updateDoc(doc(this.db, "staff", result.id), email);
    });
  }

  async deleteStaff(staffID) {
    let q = query(
      collection(this.db, "staff"),
      where("staffID", "==", staffID)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (result) => {
      await deleteDoc(doc(this.db, "staff", result.id));
    });

    const deleteStaffMember = httpsCallable(
      this.functions,
      "deleteStaffAccount"
    );
    deleteStaffMember(staffID);
  }

  // Promo

  async addPromo(restoUID: string, newPromo: any) {
    await updateDoc(doc(this.db, "restaurants", restoUID), {
      promo: arrayUnion(newPromo),
    });
  }

  async deletePromo(promo, restoUID) {
    await updateDoc(doc(this.db, "restaurants", restoUID), {
      promo: arrayRemove(promo),
    });
  }

  async updateDefault(updateDefault, restoUID) {
    await updateDoc(doc(this.db, "restaurants", restoUID), updateDefault);
  }

  // DayOff

  async addDayOff(restoUID: string, newDayOff: any) {
    await updateDoc(doc(this.db, "restaurants", restoUID), {
      dayOff: arrayUnion(newDayOff),
    });
  }

  async deleteDayOff(restoUID: string, day: any) {
    await updateDoc(doc(this.db, "restaurants", restoUID), {
      dayOff: arrayRemove(day),
    });
  }

  // PeriodOff

  async addPeriodOff(restoUID: string, newPeriodOff: any) {
    await updateDoc(doc(this.db, "restaurants", restoUID), {
      periodOff: arrayUnion(newPeriodOff),
    });
  }

  async deletePeriodOff(restoUID: string, period: any) {
    await updateDoc(doc(this.db, "restaurants", restoUID), {
      periodOff: arrayRemove(period),
    });
  }

  // Services

  async addService(userUID: string, service: any) {
    const newService = { service: service };
    await updateDoc(doc(this.db, "restaurants", userUID), newService);
  }

  async blockService(restoUID, path) {

    await updateDoc(doc(this.db, "restaurants", restoUID), { [path]: 0 });
  }

  // GeoPoint

  async geoPoint(userUID, lat, long) {
    const geopoint = new GeoPoint(lat, long);

    const coord = { pos: geopoint };

    await updateDoc(doc(this.db, "restaurants", userUID), coord);
  }

  // common value

  getPrice(): any {
    return [
      { value: "0", name: "Moins de 50€" },
      { value: "1", name: "Entre 50 à 100€" },
      { value: "2", name: "Plus de 100€" },
    ];
  }

  getRestoServices(): any {
    return [
      { value: "0", name: "Parking" },
      { value: "1", name: "Voiturier" },
      { value: "2", name: "Siège bébé" },
      { value: "3", name: "Animaux acceptés" },
      { value: "4", name: "Salle privative" },
    ];
  }

  getRestoElectric(): any {
    return [
      { value: "0", name: "CCS COMBO 2" },
      { value: "1", name: "CHADEMO" },
      { value: "2", name: "Type 2" },
      { value: "3", name: "Supercharger Tesla V2" },
      { value: "4", name: "Supercharger Tesla V3" },
      { value: "5", name: "Supercharger Tesla V4" },
    ];
  }

  getRestoPaiement(): any {
    return [
      { value: "0", name: "Carte bancaire" },
      { value: "1", name: "Chèque" },
      { value: "2", name: "Espèces" },
      { value: "3", name: "Apple Pay" },
      { value: "4", name: "Google Pay" },
      { value: "5", name: "Paylib" },
      { value: "6", name: "PayPal" },
      { value: '7', name: 'Carlo' },
    ];
  }

  getAmbiance(): any {
    return [
      { value: "0", name: "Familiale" },
      { value: "1", name: "Entre Amis" },
      { value: "2", name: "Conviviale" },
      { value: "3", name: "Intime" },
      { value: "4", name: "Vue Mer" },
      { value: "5", name: "Vue Montagne" },
      { value: "6", name: "Terrasse" },
      { value: "7", name: "Plage" },
      { value: "8", name: "Pause ski" },
      { value: "9", name: "Jardin" },
      { value: "10", name: "Contemporain" },
      { value: "11", name: "Chaleureuse" },
      { value: "12", name: "Authentique" },
    ];
  }
}
